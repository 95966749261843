<template>
   <ObiButton
      :outline="outline"
      :loading="isLoading"
      :class="`dropdown-toggle obi-dropdown-${as} fw-bold`"
      data-bs-toggle="dropdown"
      v-bind="attrs"
      v-on="$listeners"
   >
      <ObiText as="span"
         ><slot>{{ title }}</slot></ObiText
      >
   </ObiButton>
</template>

<script>
import { omit, keys } from 'lodash';

export default {
   name: 'ObiDropdownToggle',
   props: {
      title: {
         type: String,
         default: 'Dropdown',
      },
      isLoading: {
         type: Boolean,
         default: false,
      },
      outline: {
         type: Boolean,
         default: false,
      },
      as: {
         type: String,
         default: 'toggle',
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-dropdown-toggle {
   background-color: #ffffff;
   border-color: #225ac1;
   height: 100%;
   color: #225ac1;
}
.obi-dropdown-btn-profile {
   color: #ffffff !important;
   background-color: #2f71f2 !important;
   height: 100%;
}
.obi-dropdown-notification {
   background-color: transparent;
   border-color: transparent;

   &.dropdown-toggle::after {
      display: none;
   }
}
.obi-dropdown-notification:focus {
   background-color: transparent !important;
   border-color: transparent !important;
}
.obi-dropdown-notification:hover {
   background-color: transparent !important;
   border-color: transparent !important;
}
</style>
